<template>
    <div>
        <b-tabs class="st-tabs rounded-tabs" justified lazy>
            <b-tab v-for="tab in tabs" :key="tab.index" :disabled="tab.disabled">
                <template #title>
                    <div class="d-flex justify-content-center w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{ $t(tab.titleKey) }}</span>
                    </div>
                </template>
                <component
                    :is="tab.componentType"
                    :documentType="tab.documentType"
                    v-if='!isLoading'
                >
                </component>
            </b-tab>
        </b-tabs>    
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApplicationDocumentsCreated from './ApplicationDocumentsCreated';
import ApplicationDocumentsReceived from './ApplicationDocumentsReceived';

export default {
    name: 'ApplicationDocumentsList',
    components: {
        ApplicationDocumentsCreated,
        ApplicationDocumentsReceived
    },
    data() {
        return {
            tabs: [
                {
                    index: 0,
                    name: 'application-detail',
                    titleKey: 'APPLICATION.TABS.DOCUMENT_CREATED',
                    componentType: 'ApplicationDocumentsCreated',
                    disabled: false,
                    documentType: 'input'
                },
                {
                    index: 1,
                    name: 'application-documents',
                    titleKey: 'APPLICATION.TABS.DOCUMENT_RECEIVED',
                    componentType: 'ApplicationDocumentsReceived',
                    disabled: false,
                    documentType: 'output'
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        isLoading() {
            return this.loading['applications/find'];
        }
    },
    created() {
    }
}
</script>