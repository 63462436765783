<template>
    <div>
        <st-button
            v-if="hasPermissionToCreate"
            variant="primary"
            :callback="addApplicationDocument"
            class="ml-6"
        >
            <i class="fa fa-plus"></i>
            {{ $t('APPLICATION.LIST.ADD_DOCUMENT_BUTTON') }}
        </st-button>
    </div>
</template>

<script>
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
    import { mapGetters } from "vuex";

    export default {
        name: 'ApplicationDocumentsListToolbar',
        methods: {
            addApplicationDocument() {
                
            },
        },
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
            }),
            hasPermissionToCreate() {
                return new ApplicationsPermissions(this.currentUser).createDoc;
            },
        },
    };
</script>