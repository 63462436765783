<template>
    <st-filters-pagination
        stateModule="applications/form"
        :filters="filters"
        :total="totalDocs"
        @change="doFilter"
        :header="header"
        :moreFilters="false"
    >

        <template #filters-toolbar>
            <slot name="toolbar"></slot>
        </template>
            
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { DocumentModel } from '@/modules/applications/models/document-model';

    const { fields } = DocumentModel;

    const filterSchema = new FilterSchema([
        fields.created_by,
        fields.status,
        fields.created_date
    ]);

    export default {
        name: 'ApplicationDocumentsListFilter',
        props: {
            documentType: String
        },
        data() {
            return {
                filters: filterSchema.fields,
                files: [],
            };
        },
        computed: {
            ...mapGetters({
                totalDocs: 'applications/form/totalDocs',
                currentUser: 'auth/currentUser',
                fileToken: 'applications/form/fileToken',
                record: 'applications/form/record'
            }),
            hasPermissionToReadStaff() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            header() {
                if (this.documentType == 'input') {
                    return this.$t('APPLICATION.LIST.DOCUMENT_CREATED_HEADER');
                }

                return this.$t('APPLICATION.LIST.DOCUMENT_RECEIVED_HEADER');
            },
        },
        methods: {
            ...mapActions({
                doFetchDocs: 'applications/form/findFiles',
            }),
            doFilter(params) {
                // const condition = `document_created eq '${this.documentType}'`
                // if (params.query === '') {
                //     params.query = condition;
                // } else {
                //     params.query = params.query + ' and ' + condition;
                // }

                //TO DO - rethink filtering part, since it doesn't comply to standard
                this.files = [];
                if (this.record?.files?.length && this.fileToken) {
                    this.record.files.forEach(file => {
                        this.doFetchDocs({id: file.file_id, token: this.fileToken}).then((response) => {
                            response.filename = file.filename;
                            response.status = file.status;
                            
                            this.files.push(response);
                        });
                    });
                }
            },
        }
    };
</script>