<template>
    <st-page :showHeader="false">
        <application-documents-list-filter :documentType="documentType">
            <template #toolbar>
                <application-documents-list-toolbar/>
            </template>

            <application-documents-list-table :documentType="documentType" />
        </application-documents-list-filter>
    </st-page>
</template>

<script>
import ApplicationDocumentsListFilter from './ApplicationDocumentsListFilter';
import ApplicationDocumentsListTable from './ApplicationDocumentsListTable';
import ApplicationDocumentsListToolbar from './ApplicationDocumentsListToolbar';

export default {
    name: 'ApplicationDocumentsCreated',
    props: {
        documentType: String
    },
    components: {
        ApplicationDocumentsListFilter,
        ApplicationDocumentsListTable,
        ApplicationDocumentsListToolbar,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>