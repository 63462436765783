<template>
    <st-page :showHeader="false">
        <application-documents-list-filter :documentType="documentType">
            <application-documents-list-table :documentType="documentType"/>
        </application-documents-list-filter>
    </st-page>
</template>
<script>
import ApplicationDocumentsListFilter from './ApplicationDocumentsListFilter';
import ApplicationDocumentsListTable from './ApplicationDocumentsListTable';

export default {
    name: 'ApplicationDocumentsReceived',
    props: {
        documentType: String
    },
    components: {
        ApplicationDocumentsListFilter,
        ApplicationDocumentsListTable,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>