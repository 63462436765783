<template>
    <div>
        <st-data-table
            :items="files"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="onEdit"
            @download="onDownload"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { DocumentModel } from '@/modules/applications/models/document-model';

    const { fields } = DocumentModel;

    export default {
        name: 'ApplicationDocumentsListTable',
        props: {
            documentType: String
        },
        data() {
            return {
                presenter: DocumentModel.presenter,
                files: [],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'applications/form/docs',
                currentUser: 'auth/currentUser',
                fileToken: 'applications/form/fileToken',
                record: 'applications/form/record'
            }),
            fields() {
                return [
                    fields.created_by,
                    fields.filename,
                    fields.created_date,
                    fields.status.extendField({ labelType: true }),
                ]
            },
            actions(){
                if (this.documentType === 'input') {
                    return [
                        {
                            name: 'edit',
                            icon: 'edit',
                            tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                            customIcon: false,
                            type: 'primary',
                            hideOnRow: true
                        },
                        {
                            name: 'download',
                            icon: 'download',
                            tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                            customIcon: false,
                            type: 'primary'
                        },
                    ];
                } else {
                    return [
                        {
                            name: 'download',
                            icon: 'download',
                            tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                            customIcon: false,
                            type: 'primary'
                        }
                    ]
                }
            }
        },
        methods: {
            ...mapActions({
                downloadDocument: 'applications/form/downloadDocument',
                getRelatedApplicationFiles: 'applications/form/findRelatedFiles'
            }),
            onEdit(){
                this.$router.push({
                    name: 'editDocument'
                });
            },
            onDownload(doc){
                window.open(`${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileToken}`, '_blank');
            }
        },
        async created() {
            //get related application files 
            const relatedFiles = await this.getRelatedApplicationFiles(this.record.id);
            let allFiles = [];
        
            if (relatedFiles) {
                relatedFiles.forEach((file) => {
                    let outputFile = file.files.filter((el) => el.file_type === 'output');
                    allFiles.push(...outputFile);
                });
            }

            //check for attachments
            if (this.record?.identification_files?.length) {
                allFiles.push(...this.record.identification_files);
            }

            allFiles = this.rows.concat(allFiles);

            if (allFiles?.length && this.fileToken) {
                allFiles.forEach(file => {
                    if (file?.file_type === this.documentType) {
                        if (file?.created_by) {
                            file.hideAction = true;
                        }
                        
                        file.created_by = file?.created_by ?? 'system';
                        this.files.push(file);
                    }
                });
            }
        }
    };
</script>
